export const useUserStore = defineStore('user', () => {
    const user = ref({
        userID: -1,
        name: '',
        email: '',
        workspaces: [
            {
                workspaceID: -1,
                name: '',
                logo: ''
            }
        ],
        workspaceID: -1,
    });


    async function fetchUser() {
        const response = await makeApiRequest<typeof user.value>('/auth/profile');

        user.value = response;

        return response;
    }

    return {user, fetchUser};
})
